/* TYPOGRAPHY */
body, .ql-editor, .Editor-content, h1, h2, h3, h4 {
  font-family: "Plus Jakarta Sans" !important;
  color: #27272A;
}

ul {
  padding-inline-start: 0px;
}

ol, ol li {
  list-style: decimal;
  list-style-type: decimal;
  list-style-position: inside;
  padding-left: 0px;
}

li {
  list-style-type: none;
}


h1, h2, h3, h4, h5 {
  font-weight: 650;
  letter-spacing: -0.04em;
}

h1 {
  font-size: 56px;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 60px;
}

h2 {
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 8px;
}

h3 {
  font-size: 1.4em;
  margin-top: 8px;
  /* margin-bottom: 0; */
}

h4 {
  margin-top: 8px;
  margin-bottom: 0;
  color: #252828;
  /* color: rgba(0,0,0,0.88); */
  font-size: 0.96em;
}

h5 {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  margin-bottom: 8px;
  /* color: grey; */
  color: rgba(39,39,42,0.64);
}

p, .p {
  /* color: rgba(0,0,0,0.84); */
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 0;
}

a {
  text-decoration: none;
  color: #0d9488; 
}

a:hover {
  transition: 0.2s all ease-in-out;
  color: #0d9488;
}

.Strikethrough {
  text-decoration-line: line-through;
}

small {
  line-height: 20px;
  color: rgba(39,39,42,0.8);
}

img {
  display: flex;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  cursor:pointer;
}

#editor {
  height: 480px;
}



/* LAYOUTS */
.Text-center {
  text-align: center;
}

.Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* PAGES */

.App {
  text-align: center;
}

.App-container {
  height: 100vh;
  /* margin-top: -16px; */
  background-color: white;
}

.Page-container {
  display: flex;
  /* margin-top: -16px; */
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  padding-bottom: 80px;
}

.Page-container::-webkit-scrollbar {
  display: none;
}

.Page-cover-container {
  width: 100%;
  height: 240px;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 32px;
  background-color:rgba(0, 0, 0, 0.04);
}

.Page-cover-container-hero {
  width: 100%;
  height: 640px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 16px;
  margin-bottom: 32px;
  margin-top: 80px;
}

.Page-wide {
  width: 100%;
}

.Page-medium {
  width: 1200px;
}

.Page-small {
  width: 960px;
}

.Page-xsmall {
  width: 640px;
}

.Page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 108px;
  gap: 8px;
}

.Page-title {
  display: flex;
  flex-grow: 1;
  margin-bottom:0;
  align-items: center;
}

/* FOCUSED TASK */
.Focused-task-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  z-index: 98;
}

.Section-header {
  padding: 4px;
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}


/* ONBOARDING */
.Onboarding-container {
  width: 100%;
}

.Onboarding-content {
  margin-right:auto;
  margin-left: auto;
  width: 400px;
}

.Onboarding-image {
  background-size: cover;
  width: 100%;
  height: 480px;
  border-radius: 32px;
  margin-bottom: 24px;
}

/* ADJUSTMENTS */

.flex {
  display: flex;
  /* align-items: center; */
  gap: 8px;
}

.flex-grow {
 flex-grow: 1;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.align-center {
  align-items: center;
}

.Truncate { 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  width: 100%; 
}


/* LOGO */

.Logo {
  object-fit: contain;
  pointer-events: none;
  width: 120px;
}

.Logo-small {
  object-fit: contain;
  pointer-events: none;
  width: 40px;
}

.Logo-medium {
  object-fit: contain;
  pointer-events: none;
  width: 80px;
}

.Logo-big {
  object-fit: contain;
  pointer-events: none;
  width: 120px;
}

.Logo-full {
  object-fit: contain;
  width: 160px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ANIMATION */
.Fade-in {
  opacity: 0;
  animation: fade-in 1s cubic-bezier(0.075, 0.5, 0.25, 1) forwards;
  position: relative;
}

@keyframes fade-in {
  from {
    opacity: 0;
    top: 40px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

/* LOADING */
.Loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.Loader:empty { 
  position: absolute;
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
  width: 3em;
  height: 3em;
  border: 0.7em solid rgba(0, 0, 0, 0.04);
  border-left: 0.7em solid #27272A;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}

.Loader-quote {
  padding-top: 180px;
  text-align: center;
  margin-bottom: 4px;
  opacity: 0.56;
}

.Loader-author {
  margin-top: 8px;
  font-size: 16px;
  color: grey;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.Loading-generation {
  background: linear-gradient(-45deg, #3e5bdf, #42cbad, #fed036, #ff7852, #e655a0);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
	height: 12px;
  /* height: 100vh; */
  width: 100%;
  border-radius: 12px;
  /* filter: blur(2px); */
  margin-bottom: 24px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* NAVIGATION */

.Main-nav-container {
  display: flex;
  list-style-type: none;
  flex-flow: row wrap;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  background-color: #FFF;
  padding: 0 16px 0 0 ;
  /* border-bottom: 1px solid rgba(0,0,0,0.08); */
  position: fixed;
  width: 100%;
  top: -16px;
  /* box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04); */
  /* box-shadow: 0px 4px 8px white; */
  z-index: 97;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Bottom-nav-container {
  display: flex;
  list-style-type: none;
  flex-flow: row wrap;
  align-items: center;
  align-content: space-between;
  justify-content: space-around;
  background-color: #FFF;
  border-top: 1px solid rgba(0,0,0,0.08);
  position: fixed;
  width: 100%;
  bottom: -16px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
  z-index: 97;
  /* gap: 8px; */
}



.Bottom-nav-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* flex-grow: 1; */
  flex-direction: column;
  width: 20%;
  padding: 16px 0px 16px 0px;
  /* border-bottom: 4px solid #FFFFFF; */
}

.Bottom-nav-tab svg {
  height: 24px;
  width: 24px;
  color: #27272A;
}

.Bottom-nav-tab p {
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(24, 34, 34, 0.44);
  
}

.Bottom-nav-tab svg {
  color: rgb(24, 34, 34, 0.44);
}

.active {
  color: #0e8d79;
  
  /* background-color: red; */
  /* border-bottom: 4px solid #27272A; */
}

.active svg {
  color: #27272A;
}

.active p {
  color: #27272A;
  font-weight: 600;
}

.is-active {
  background-color: yellow;
  border-bottom: 0.4rem solid teal;
  cursor: not-allowed;
}

.nav-link{ 
  text-decoration: none;
  background-color: white;
  color: #0E1333;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.nav-link:hover, .active:hover {
  background-color: rgba(0,0,0,0);
}

.Main-nav-container button {
  margin: 8px 4px;
}

.Main-nav-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Main-nav-tab a {
  padding: 14px 20px;
  margin: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  flex-direction: row;
}


.Main-nav-tab a:hover {
  transition: 0.2s all ease-in-out;
  background: #F4F4F5;
  border-radius: 24px;
}


.Main-nav-tab p {
  margin-bottom: 0;
}

.Main-nav-tab a .Logo {
  color: black; 
}

.Main-nav-tab-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}



/* SEARCH */

.Search-bar {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
  border-radius: 44px;
  width: auto;
  font-size: 16px;
  /* margin-bottom: 32px; */
}

.Search-input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 8px;
  outline: none;
  font-size: 16px;
}

.Search-input-default {
  padding: 4px 8px;
}

.Search-input-large {
  padding: 8px 16px;
}


/* Editor */
.ql-container {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  border-top: 0px !important;
}


.ql-toolbar.ql-snow {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  font-family: 'Plus Jakarta Sans' !important;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  border-bottom: 0px !important;
}

.ql-editor {
  line-height: 1.68 !important;
}


.ql-blank {
  min-height: 120px;
}

.Editor-toggle-button {
  padding: 8px;
  cursor: pointer;
  margin: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 0px;
  color:rgb(24, 34, 34, 0.44)
}

.Editor-toggle-button:hover {
  background-color: #F4F4F5;
  transition: 0.2s all ease-in-out;
}

/* INPUTS */

input {
  /* text-transform: capitalize; */
}



.Checkbox {
  display: block;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  margin-top: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffff;
  border: 2px solid rgba(0,0,0,0.16);
  border-radius: 4px;
}

.Checkbox:hover input ~ .Checkmark {
  background-color: #eee;
}

.Checkbox input:checked ~ .Checkmark {
  background-color: #27272A;
  border: 2px solid #4a4f4f;
}

.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Checkbox input:checked ~ .Checkmark:after {
  display: block;
}

.Checkbox .Checkmark:after {
  left: 6px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-bottom-right-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .Checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0,0,0,0.08);
} */

input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Form-input-container {
  gap: 0;
  display: flex;
  flex-direction: column;
}

.Form-input {
  border: 1px solid rgba(0, 0, 0, 0.16);
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08); */
  border-radius: 44px;
  width: auto;
  padding: 12px 16px;
  margin-bottom: 8px;
  font-size: 16px;
}

.Form-input-title {
  border: 0px solid;
  margin: 0;
  padding: 8px 0;
  border-radius: 4px;
  outline: none;
  font-size: 2.0em;
  font-weight: 700;
  letter-spacing: -0.04em;
  font-family: "Plus Jakarta Sans";
  width: 100%;
}

.Form-input-title::placeholder {
  color:rgb(24, 34, 34, 0.44) !important;
}

.Form-input-subtle {
  border: 0px solid;
  margin: 0;
  padding: 8px 0;
  border-radius: 4px;
  outline: none;
  background-color: rgba(0,0,0,0);
  font-size: 16px;
}

.Form .Button-group {
  justify-content: flex-end;
  width: 100%;
}

.Form button, .Button-wide {
  /* width: 100%; */
}

textarea {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: auto;
  padding: 8px 16px;
  margin-bottom: 8px;
  font-family: "Plus Jakarta Sans";
  min-height: 88px;
  height: auto;
  line-height: 22px;
  resize: none;
  white-space: pre-line;
  font-size: 16px;
}

label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-top: 20px;
  margin-bottom: 8px;
  color: #71717a;
  align-items: center;
  display: flex;
  gap: 6px;
}

.Input-error {
  color: #ef4444;
  font-weight: 500;
  padding: 8px;
  margin: 8px;
}

/* DATE PICKER */
input[type=date] {
  background-color: white;
  /* padding-right: 12px; */
  padding: 0 12px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  color: rgba(0,0,0,0.5);
  width: 90%;
  height: 40px;
}
::-webkit-datetime-edit { 
    padding: 1em;
  }
::-webkit-datetime-edit-fields-wrapper {
  background: white; 
}


/* SKELETON LOADER */
.Skeleton-loader-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
}

.Skeleton-card {
  background-color: #FFF;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #F4F4F5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}

.Skeleton-loader-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Skeleton-pills {
  display: flex;
  gap: 8px;
  padding: 16px;
}

.Skeleton-pill {
  height: 32px; 
  width: 64px;
  border-radius: 16px;
  background: linear-gradient(-45deg, #fafafa, #f4f4f5, #e4e4e7, #d4d4d8, #e4e4e7, #f4f4f5, #fafafa);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
}

.Skeleton-list {
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #F4F4F5;
  gap: 24px;
  padding: 12px;
  
}

.Skeleton-card-image {
  height: 150px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: linear-gradient(-45deg, #fafafa, #f4f4f5, #e4e4e7, #d4d4d8, #e4e4e7, #f4f4f5, #fafafa);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
}

.Skeleton-card-content {
  display: flex;
  flex-direction: column;
  padding: 8px 12px 12px;
  gap: 8px;
}

.Skeleton-card-title {
  height: 16px;
  border-radius: 8px;
  width: 80%;
  background-color: #e4e4e7;
  margin-bottom: 5px;
  
}

.Skeleton-card-description {
  height: 12px;
  border-radius: 6px;
  width: 60%;
  background-color: #e4e4e7;
}

.Skeleton-card-button {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #e4e4e7;
}


/* TAGS */

.Tag {
  font-size: 14px;
  color: rgba(0,0,0,0.4);
  padding: 0 8px;
  background-color: rgba(0,0,0,0.02);
  border: 1px solid rgba(0,0,0,0.07);
  border-radius: 4px;
}

.Tag-group {
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.Tag-group-container {
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
}

.Tag-group-container:hover {
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: rgba(0, 0, 0, 0.04);
}

.Tag-picker-popover-container {
  padding: 16px;
  width: 320px;
}

.Tag-picker-popover-container label {
  margin-bottom: 8px;
}

/* CARD COLOR */
.Card-color-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
}

.Card-color {
  height: 6px;
  width: 6px;
  border-radius: 8px;
  display: block;
  flex-shrink: 0;
  margin: 2px;
}


/* AVATARS */
.Avatar {
  display: flex;
  object-fit: cover;
  /* background-color: rgba(0,0,0,0.08); */
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f5;
  margin: 2px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.Avatar-xLarge {
  width: 160px;
  height: 160px;
}

.Avatar-large {
  width: 64px;
  height: 64px;
}

.Avatar-medium {
  width: 32px;
  height: 32px;
}

.Avatar-small {
  width: 24px;
  height: 24px;
}

.Avatar-xSmall {
  width: 16px;
  height: 16px;
}

.Avatar-group {
  display: flex;
  flex-direction: row;
}

.Avatar-photo {
  position: absolute;
  width: 100%;
  height: 100%;
}




.twemoji-head {
  position: absolute;
  top: 80px;
}

.twemoji-body {
  position: absolute;
  width: 100px;
}

/* AVATAR PICKER */
.Avatar-emoji-container, .Avatar-color-container  {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.Avatar-emoji-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  background-color: rgba(0,0,0,0.04);
  border: 2px solid rgba(0,0,0,0);
}

.Avatar-emoji-select-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  /* background-color: #27272A; */
  border: 2px solid rgba(0,0,0,0.8);
  /* background-color: rgba(0, 155, 60, 0.12);
  border: 1px solid rgba(9, 131, 72, 1.0); */
}

.Avatar-color-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  border: 2px solid rgba(0,0,0,0.08);
}

.Avatar-color-select-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  border: 2px solid rgba(0,0,0,0.8);
}

.Avatar-emoji-select input[type="radio"], 
.Avatar-emoji-select-active input[type="radio"],
.Avatar-color-select input[type="radio"], 
.Avatar-color-select-active input[type="radio"] 
{ 
   width: 32px;
   height: 32px;
   content: none;
   outline: none;
   margin: 0;
   opacity: 0;
   position: absolute;
}

/* BADGE */
.Badge {
  background: red;
  padding: 2px 4px;
  border-radius: 8px;
  color: white;
  margin-left: 4px;
  margin-right: 4px;
}

/* BUTTONS */
button {
  border-radius: 24px;
  width: fit-content;
  font-family: "Plus Jakarta Sans";
}

.Button-group {
  display: flex;
  gap: 4px;
  margin: 4px 0;
  align-items: center;
  justify-content: flex-end;
}

.Button-group-calc {
  display: flex;
  gap: 0;
}

.Button-group-calc input {
  width: 40px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 0;
  text-align: right;
  box-shadow: 0px 1px 10px rgba(0,0,0,0);
}

.Button-group-calc button:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 4px 12px;
}

.Button-group-calc button:nth-child(3) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 4px 12px;
}

.Button-group-vertical {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px 0;
}

.Button-primary {
  /* background-color: #0fb55c; */
  background-color: #27272A;
  cursor: pointer;
  color: white;
  padding: 14px 20px;
  border: none;
  font-weight: 600;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2 all cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
  
}

.Button-primary:hover {
  background-color: #27272A;
  transition: 0.2s all ease-in-out;
  
}

.Button-secondary {
  background-color: rgba(0, 155, 60, 0.12);
  cursor: pointer;
  color: #27272A;
  padding: 14px 20px;
  border: none;
  font-weight: 600;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2 all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Button-secondary:hover {
  background-color: rgba(0, 155, 60, 0.08);
  transition: 0.2s all ease-in-out;
}


.Button-secondary-active {
  background-color: #27272A;
  color: white;
}

.Button-secondary-active:hover {
  background-color: #27272A;
  transition: 0.2s all ease-in-out;
}


.Button-default {
  /* background-color: rgba(0,0,0,0.04); */
  background-color: white;
  cursor: pointer;
  color: grey;
  border: 1px solid rgba(0,0,0,0.12);
  font-weight: 600;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.Button-default:hover {
  /* background-color: rgba(0,0,0,0.04); */
  color: #27272A;
  transition: 0.2s all ease-in-out;
}

.Button-full {
  width: 100%;
}

.Button-link {
  opacity: 0.8;
  cursor: pointer;
}

.Button-link:hover {
  opacity: 1.0;
  text-decoration: underline;
  transition: 0.3s all ease-in-out;
}

.Button-dotted {
  box-shadow: none;
  border: 0px solid rgba(0,0,0,0.4);
  /* border-style: dashed; */
  padding-top: 16px;
  padding-bottom: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(0,0,0,0.3)' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  background-color: rgba(0,0,0,0.024);
}

.Button-connect {
  background-color: white;
  cursor: pointer;
  padding: 12px 20px 12px 12px;
  border: 1px solid rgba(0,0,0,0.12);
  color: rgba(0,0,0,0.72);
  font-size: 16px;
  font-weight: 600;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;
  width: 100%;
}

.Button-connect:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.2s all ease-in-out;
}

.Button-img {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.Button-delete {
  background-color: white;
  cursor: pointer;
  /* color: rgba(0,0,0,0.72); */
  padding: 12px 20px;
  border: 1px solid rgba(0,0,0,0);
  font-weight: 600;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(207, 41, 41);
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.Button-delete:hover {
  background-color: rgb(255, 231, 231);
  color: rgb(181, 10, 10);
  transition: 0.2s all ease-in-out;
  border: 1px solid rgb(255, 65, 65)
}

.Destructive {
  color: rgb(207, 41, 41);
}

.Button-icon {
  background-color: white;
  cursor: pointer;
  color: rgba(0,0,0,1);
  padding: 8px;
  border: 0px solid rgba(0,0,0,0.12);
  font-weight: 600;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  /* width: 32px;
  height: 32px; */
  font-size: 16px;
  color: rgba(0,0,0,0.5);
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Button-icon:hover {
  border: 0px solid rgba(0,0,0,0.12);
  /* background: rgba(0,0,0,0.04); */
  background-color: #F5F5F4;
  color: white;
}

.Button-subtle {
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  padding: 8px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  gap: 8px;
  font-weight: 600;
}

.Button-subtle:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.2s all ease-in-out;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.Button-active, .Button-icon-active {
  background-color: #0b934b;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.16);
  padding: 4px;
  display: flex;
}

.Button-active:hover {
  background-color: #0b934b;
  color: white;
}

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  font-weight: 500;
  padding: 16px;
  color: rgba(0,0,0,0.64);
  
}


:where(.css-dev-only-do-not-override-ixblex).ant-btn-default:not(:disabled):hover {
  color: black;
  border-color: black;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Button-generate {
  display: flex;
  gap: 8px;
  padding: 8px 20px;
  text-align: center;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  background-size: 200% auto;
  color: white;
  border-radius: 32px;
  border: 0px;
  font-weight: 600;
  font-size: 14px;
  background: #27272A;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Button-generate:hover {
  background: rgba(0,0,0,0);
  color: white;
}

.Button-generating {
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  transition: 0.5s all;
  background-size: 200% auto;
  color: white;
  border-radius: 32px;
  border: 0px;
  font-weight: 600;
  font-size: 14px;
  background: linear-gradient(-45deg, #3e5bdf, #42cbad, #fed036, #ff7852, #e655a0);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Button-generate-border {
  position: relative;
  border-radius: 32px;
  background-size: 200% auto;
  background-image: linear-gradient(to right, #3e5bdf 0%, #42cbad 25%, #fed036 50%, #ff7852 75%, #e655a0 100%);
  /* background: conic-gradient(#3e5bdf, #42cbad, #fed036, #ff7852, #e655a0, #3e5bdf); */
  transition: 0.5s;
  /* box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%); */
}

.Button-generate-border:hover {
  background-position: right center;  
  /* background: conic-gradient(#3e5bdf 0.09turn, #42cbad 0.09turn, #fed036, #ff7852, #e655a0, #3e5bdf); */
  
}

/* SELECTS */
.ant-select-selection-item, .ant-select-item-option-content  {
  font-size: 16px !important;
}
.ant-select-selector {
  /* padding: 4px !important; */
  font-size: 16px !important;
}

/* FLOATING ACTION BAR */
.Floating-action-bar-container {
  position: fixed;
  bottom: 88px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}

.Floating-action-bar {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #18181b;
  padding: 8px;
  border-radius: 120px;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}


/* CHAT BUBBLE */
.Chat-bubble-container {
  /* width: 100%; */
}

.Chat-bubble-from {
  color: white;
  font-size: 24px;
  padding: 16px;
  line-height: 72px;
  background: #1E75F2;
  border-radius: 32px;
  border-bottom-left-radius: 8px;
  width: 100%;
}

.Chat-bubble-to {
  color: white;
  font-size: 24px;
  padding: 16px;
  line-height: 72px;
  background: #00BB13;
  border-radius: 32px;
  border-bottom-right-radius: 8px;
}

.Chat-scroll-affordance {
  display: flex;
  position: fixed;
  bottom: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: white;
}

/* MODAL */
.Modal {
  background: white; 
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 16px;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 640px;
  gap: 8px;
  z-index: 99;
}

.Modal-blanket {
  background: rgba(0,0,0,0.4);
  height:100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}

.ant-modal-content {
  padding: 0 !important;
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.ant-modal-close {
  background-color: white !important;
  border-radius: 100% !important;
  color: rgba(0,0,0.0.24) !important;
  width: 32px !important;
  height: 32px !important;
  left: 16px !important;
}

.Modal-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 40px;
  border-radius: 0;
  
}

.ant-modal {
  border-radius: 32px;
  overflow: scroll;
}

.ant-modal-header {
  margin-bottom: 0 !important;
}



.ant-modal-title .Button-group {
  padding: 16px ;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}


.ant-modal-title {
  background-color: white !important;
}

.Modal-header-container {
  width: 100%;
  padding: 12px 20px 12px 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}


.Modal-content-container {
  width: 640px;
}

.Modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  border-radius: 16px;
}

.Modal-cover {
  background-color: #1F452F;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 24px 40px;
  height: 200px;
  overflow: hidden;
  border-bottom-left-radius: 100% 50%;
  border-bottom-right-radius: 100% 50%;
}


/* CAROUSEL */
.Carousel-slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 50%;
  background-color: #F4F4F5;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}

.Carousel-slideshow-slider {
  white-space: nowrap;
  transition: all cubic-bezier(.57,0,.55,1) 800ms;
  
}

.Carousel-slide {
  width: 100%;
  display: inline-block;
  padding-top: 160px;
  padding-bottom: 160px;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}


.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.Carousel-slideshow-slide img {
  max-width: 100%;
  max-height: 100%;
}

.Carousel-slideshow-dots {
  position: absolute;
  left: 45%;
  transform: translateX(-35%);
  bottom: 32px;
  width: 100%;
}

.Carousel-slideshow-dot {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 16px;
  border-radius: 50%;
  cursor: pointer;
  margin: 8px;
  color: white;
}

.Carousel-slideshow-dot.active {
  background-color: #fff;
  color: #18181B;
  transition: 0.3s cubic-bezier(.57,0,.55,1);
}

/* POPOVER */
.Popover-date-picker {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* MORE MENU */
.ant-popover-inner {
  padding: 0 !important;
  min-width: 160px !important;
}

.Dropdown-menu-item {
  display: flex; 
  cursor: pointer; 
  gap: 8px;
  padding: 12px;
}

.Dropdown-menu-item p {
  font-weight: 500;
}


.Dropdown-menu-item:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* .Dropdown-menu-item:first-child {
  padding-top: 16px;
}

.Dropdown-menu-item:last-child {
  padding-bottom: 16px;
} */


.ant-popover-content p {
  margin: 0;
}

/* Inline create */
.Inline-create-container {
  padding: 24px;
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
  position: relative;
  z-index: 10;
}

.Inline-create-container .Button-generate {
  background-color: white;
  color: black;
}

.Inline-create-container .Button-generate:hover {
  background: rgba(0,0,0,0);
  color: white;
}

.Inline-create-container .Button-primary, .Inline-create-container .Button-default {
  width: 100%;
}

.Inline-create-container .Button-icon {
  border: 0px;
}

.Inline-create-container .Form-input-subtle {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.Inline-create-container input[type=date] {
  width: 144px;
  height: 40px;
}


/* CARDS */
.Card-group {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 8px;
  margin-bottom: 8px;
}

.Card {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  height: auto;
}


/* HOME */
.Section-hero {
  display: flex;
  /* flex-direction: column; */
  padding-bottom: 56px;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
  /* background-color: #1F452F; */
  /* background-color: #133e25; */
  /* background: linear-gradient(180deg, #1F452F -21.45%, #27272A 81.6%); ; */

  margin-left: auto;
  margin-right: auto;
}

.Section-hero-content {
  width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* text-align: center; */
  /* color: white; */
}

.Section-hero-image {
  width: 1200px;
}

.Section-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba(0,0,0,0.04);
  border-radius: 12px;
}

.Section-emoji img {
  width: 24px;
  height: 24px;
}


.Section-hero-content h2 {
  margin-bottom: 24px;
}

.Section-hero-content button, .Section button {
  padding: 16px 20px;
  border-radius: 48px;
  font-size: 16px;
  /* width: 160px; */
}

.Section {
  display: flex;
  width: 100%;
  border-radius: 16px;
  /* background-color: rgba(0,0,0,0.02); */
  /* margin-bottom: 24px; */
  padding: 16px 0;
  gap: 24px;
  /* border: 1px solid rgba(0,0,0,0.08);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06); */
}

.Section-content-text {
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: 100%;
  border-radius: 48px;
  /* background-color: #f5f5f4; */
  flex: 1;
}

.Section-content-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 48px;
  flex: 2;
  /* border: 1px solid rgba(0,0,0,0.08); */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06); */
  overflow: hidden;
}

.Section-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 24px 0;
}

/* TABS */
.Tabs-controls {
  display: flex;
}


.Tab {
  background-color: white;
  padding: 12px 20px;
  font-weight: 500;
  color: rgba(0,0,0,0.40);
  cursor: pointer;
  /* border-radius: 24px; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid rgba(0,0,0,0.08);
}

.Tab-active {
  color: #27272A;
  /* background-color: rgba(0, 155, 60, 0.12); */
  font-weight: 600;
  border-bottom: 3px solid #27272A;
}

.Tab-content {
  display: none;
}

.Tab-content-active {
  display: block;
}



/* TOASTER */
.Toaster {
  background-color: #27272A !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;  
  font-family: "Plus Jakarta Sans" !important;
}

/* RECIPES */

.Recipe-card-list {
  display: flex;
  list-style-type: none;
  padding-inline-start: 0;
  gap: 8px;
  flex-flow: row wrap;
  flex-direction: row;
  align-content: space-between;
  /* justify-content: space-around; */
}


.Recipe-card-container {
  position: relative;
}

.Recipe-grid .Recipe-card {
  width: calc((100% - 24px)/3) !important;
}

.Heart-filled {
  transform: scale(0.64);
}


.Recipe-card {
  display: flex;
  list-style-type: none;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  align-items: flex-start;
  /* width: 312px; */ 
  margin-bottom: 4px;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Recipe-mini-card {
  display: flex;
  flex-direction: column;
  /* padding: 2px 4px 4px 4px; */
  padding: 2px 4px;
  border-radius: 4px;
  color: #27272A;
  /* background-color: rgb(25, 82, 25); */
  background-color: #fff;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 2px;
  gap: 2px;
  /* white-space: pre-line; */
}

.Recipe-mini-thumbnail {
  width: calc((100vw / 7)-16);
  height: 40px;
  border-radius: 8;
  background-color: rgba(0,0,0,0.02);
  /* overflow: hidden; */
}

.Recipe-mini-card .ant-tag {
  font-size: 8px;
  margin: 0;
  padding-inline: 4px;
}

.Recipe-mini-card small {
  font-weight: 600;
  font-size: 10px;
  overflow: hidden;
  opacity: 100%;
  line-height: 12px;
}

.fc-event-main .Recipe-card {
  width: 100% !important;
}

.Recipe-card-content {
  padding: 8px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;
  white-space: pre-line;
  
}

/* .Recipe-card-content .Button-icon {
  height: 32px;
  width: 32px;
  padding: 4px;
  border: none;
}

.Recipe-card-content .Button-default {
  padding: 8px 12px;
  border: 1px solid rgba(0,0,0,0.16);
  box-shadow: none;
} */

.Recipe-card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0,0,0,0.16);
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  /* color: #FFF;
  background-color: #27272A; */
  transform: rotate(1deg) translateY(-8px);
}

.Recipe-card h3 {
  margin-bottom: 0;
  margin-top: 8px;
}

.Recipe-card h4 {
  font-size: 0.88rem;
  width: 100%;
  box-sizing: border-box;
}

.Recipe-card:hover h4 {
  text-decoration: underline;
  /* color: #FFFFFF; */
}

.Recipe-card p {
  margin-top: 0;
  margin-bottom: 0;
}

.Recipe-card small {
  /* height: 100%; */
  width: 100%;
  font-size: 0.7rem;
}

.Home-page .Recipe-card {
  width: calc((100% - 24px)/3);
}

.Recipe-horizontal-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 8px;
  padding: 8px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Recipe-horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.Recipe-horizontal-scroll .Recipe-card {
  width: 160px;
  display: inline-block;
  flex: 0 0 auto;
}

.Recipe-horizontal-scroll .Recipe-card .Recipe-thumbnail {
  height: 160px;
}

.Recipe-horizontal-scroll .Recipe-card .Recipe-author {
  display: none;
}


.Recipe-thumbnail {
  width: 100%;
  height: 120px;
  border-radius: 0;
  background-color: rgba(0,0,0,0.02);
  overflow: hidden;
}

.Recipe-create-modal {
  padding: 24px;
}


.Thumbnail {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  background-color: rgba(0,0,0,0.02);
  overflow: hidden;
}


/* CAROUSEL */
.Horizontal-scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* COLLECTIONS */
.Collection-card-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: scroll;
  gap: 8px;
}

.Collection-card-list .Recipe-card {
  background-color: #27272A;
  color: white;
  border-radius: 20px;
  border: none;
  /* padding: 16px; */
}


.Collection-card-list .Recipe-card img {
  /* width: 100%; */
  /* border-radius: 16px; */
  border-bottom-left-radius: 60% 30%;
  border-bottom-right-radius: 60% 30%;
}

.Collection-card-content {
  padding: 24px;
  white-space: pre-line;
}

.Collection-card-list .Recipe-card h4 {
  color: white;
}

/* FULL CALENDAR */

.Calendar-container {
  background-color: white;
  transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.3s ease-out;
}
.fc-view-harness {
  /* border: 1px solid rgba(0,0,0,0.12); */
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.fc-event {
  border-radius: 16px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border-top: 0px solid rgba(0,0,0,0) !important;
  border-left: 0px solid rgba(0,0,0,0.08) !important;
  border-right: 0px solid rgba(0,0,0,0.08) !important;
}

.fc-theme-standard th {
  border: 0px solid rgba(0,0,0,0) !important;
  border-bottom: 0.5px solid rgba(0,0,0,0.12) !important;
}

.fc-theme-standard td {
  /* border-color: black !important; */
}

.fc-h-event {
  background-color: white !important;
  border: white !important;
}

.fc .fc-highlight {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.fc .fc-col-header-cell-cushion {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  padding: 8px !important;
  line-height: 14px;
}

.fc-day-today .fc-col-header-cell-cushion{
  color: #27272A;
  /* background-color: rgba(0, 0, 0, 0.04); */
  /* background-color: #27272A; */
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border-radius: 0px;
  padding-bottom: 24px;
  border-bottom: 4px solid #27272A;
}

.fc-scrollgrid-sync-inner{
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background-color: white;
}

.fc-daygrid-day-frame {
  cursor: pointer !important;
  transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  display: flex !important;
  /* flex-grow: 1 !important; */
  width: 33%;
  justify-content: center;
}

.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
  justify-content: flex-start;
}

.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child {
  justify-content: flex-end;
}

.fc-button-group {
  border-radius: 8px !important;
  overflow: hidden;
  /* border: 1px solid rgba(0,0,0,0.2) !important; */
  box-shadow: 0px 1px 24px rgb(0 0 0 / 4%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.fc-button-group .fc-button:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.fc-button-group .fc-button:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.fc .fc-button {
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1.0 !important;
  padding: 8px 12px !important;
  /* border-radius: 8px !important; */
  /* margin-right: 2px !important;
  margin-left: 2px !important; */
  /* border-color: rgba(0,0,0,0.08) !important; */
  cursor: pointer !important;
  /* box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%); */
}

.fc .fc-button:focus, .fc .fc-button-primary:focus {
  outline: 0 !important;
  color: #27272A !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.fc .fc-button:hover, .fc .fc-button-primary:hover {
  background-color: rgba(0,0,0,0.04) !important;
  transition: 0.2s all ease-in-out !important;
}



.fc .fc-button-primary {
  background-color: white !important; 
  color: rgba(0,0,0,0.64) !important;
  border-color: rgba(0,0,0,0.08) !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none;
}

.fc .fc-button-primary:hover,
.fc .fc-button .fc-button-primary .fc-button-active  {
  color: white !important;
  background-color: #27272A !important;
}


.fc .fc-button-primary .fc-button-active  {
  color: white !important;
  background-color: #27272A !important;
}



.fc-event-title-container {
  background-color: #0fb55c;
  padding: 8px;
}

.fc-scrollgrid-sync-inner {
  padding: 8px;
}

.fc-daygrid-day-frame:hover {
  /* background-color: rgba(0, 0, 0, 0.04) !important; */
  background-color: #f5f8f8 !important;
}

.fc-daygrid-day-frame:focus {
  background-color: rgba(0, 155, 60, 0.04) !important;
}

.fc-day-today:hover, .fc-day-today:active, .fc-day-today:focus {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.fc-col-header-cell-cushion {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(132, 132, 132);
}

.fc-daygrid-day-frame {
  background-color: white;
}

.fc-h-event .fc-event-main {
  color: black !important;
}


/* FC list view */
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgba(0,0,0,0.04);
  padding: 8px 16px;
  font-size: 12px;
}

.fc .fc-list-event-dot{
  border-color: #0b934b;
}

.fc-list-event-title .Recipe-card {
  width: 100% !important;
}

.fc .fc-list-table {
  margin-bottom: 96px;
}


.fc-list-event-title .Recipe-card h4 {
  font-size: 16px;
}

.fc-list-event-title .Recipe-thumbnail {
  height: 240px;
}

.fc .fc-list-empty {
  background-color: white !important;
  height: 80% !important;
}



.Calendar-event {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  font-family: "Plus Jakarta Sans";
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Calendar-event:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0,0,0,0.16);
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Calendar-event-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  flex-wrap: wrap;
  /* text-align: center; */
}

.Calendar-event h4 {
  margin-top: 0;
  margin-bottom: 0;
  /* text-overflow: ellipsis; */
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  /* width: 120px; */
}

.Calendar-event small {
  margin-top: 4px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  line-height: 16px;
}

.Calendar-event-thumbnail {
  width: 100%;
  height: 160px;
  border-radius: 0;
  display: flex;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
  flex-direction: column;
}

.Calendar-add-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* PLAN SIDEBAR */
.Plan-page-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  width: 100%;
/*   
  overflow: scroll;
  width: 100%;
  justify-content: center;
  position: relative; */
  /* align-items: stretch; */
}

.Plan-page-container .Page-container {
  display:block;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
  
}

.Plan-page-container::-webkit-scrollbar {
  display: none;
}

.Plan-sidebar {
  /* background-color: rgba(0,0,0,0.04); */
  background-color: white;
  width: 320px;
  height: 100%;
  /* position: absolute; */
  right: 0;
  top: 0;
  overflow: scroll;
  padding: 24px;
  margin-top: 88px;
  margin-right: 16px;
  /* box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.06); */
  border-left: rgba(0,0,0,0.16);
  border: 1px solid rgba(0,0,0,0.16);
  border-radius: 16px;
  z-index: 95;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.Plan-sidebar .Page-title {
  padding-bottom: 16px;
}
.Plan-sidebar h3 {
  margin-bottom: 8px;
}

.Plan-sidebar .Recipe-card {
  /* flex-direction: row; */
  margin-bottom: 8px;
  justify-content: flex-start;
  width: 100% !important;
}

.Plan-sidebar .Button-delete  {
  /* display: none; */
}

.Plan-sidebar .Recipe-thumbnail {
  /* width: 96px; */
  height: 96px;
}

/* GROCERIES */
.Grocery-container {
  display: flex;
}

.Grocery-area {
  width: 960px;
}


.Pills {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  justify-content: flex-start;
  overflow: scroll;
  width:100%;
  flex-wrap: wrap;
}

.Pill {
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0,0);
  background-color: #F4F4F5;
}

.Pill:hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1.5px solid rgba(0,0,0,0.08);
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1)
}

.Pill p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
}

.Pill .twemoji {
  height: 16px;
  width: 16px;
  align-items: flex-end;
}


.Grocery-cart-container{
  display: flex;
  list-style-type: none;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
  width: 280px;
}

.Grocery-form-add-container {
  display: flex;
  flex-direction: row;
  /* padding-left: 24px; */
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  gap: 12px;
  align-items: center;
  border-radius: 8px;
}

.Grocery-form-add-container:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Grocery-form-add-container input {
  flex-grow: 1;
  /* width: 80%; */
  /* margin-bottom: 16px; */
}

.Grocery-list {
  margin: 0;
}



/* LISTS */

.List-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.12);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  overflow: hidden;
}

.List-items {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 8px;
  list-style-type: circle;
}

ol .List-items, ul .List-items {
  padding-left: 4px
}


.List-item-subtle {
  display: flex;
  gap: 8px;
  padding: 8px;
  padding-right: 8px;
  padding-left: 8px;
  align-items: center;
  /* align-items: flex-start; */
  border-radius: 8px;
}

.List-item-subtle.align-center {
  align-items: center;
}

.List-item-subtle.align-top {
  align-items: flex-start;
}

.List-item-subtle:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.List-item-subtle .Form-input-subtle {
  /* padding: 0px 4px; */
  padding: 4px;
  width: fit-content;
}

.Bullet-number {
  width: 32px;
  height: 32px;
  background-color: #27272A;
  border-radius: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Bullet-number p {
  color: white;
  margin-bottom: 0;
  font-size: 11px;
}




.List-item-bordered {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.08);
  justify-content: space-between;
  cursor: pointer;
}

.List-item-bordered h4 {
  flex-grow: 1;
}

.List-item-bordered:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.2s all ease-in-out;
}

.List-item-bordered p, .List-item-bordered h4 {
  margin-bottom: 0;
  margin-top: 0;
  /* width: 100%; */
}

.List-item-bordered-value {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.List-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 8px;
}



/* INSIGHTS */
.recharts-legend-icon {
  height: 16px !important;
  width: 16px !important;
  border-radius: 8px !important;
}

.Chart-title h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.Chart-tile {
  padding: 24px;
  padding-bottom: 40px;
  height: auto;
  /* width: 100%; */
}

.Chart-tile small {
  width: 100%;
  line-height: 20px;
  margin-bottom: 16px;
}

.half {
  width: 50%;
}


/* PREFERENCES */
.Preferences-card-list {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.Preferences-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  
}

.Preferences-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 24px 16px 24px;
}

.Preferences-header h2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.Preferences-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.12);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  overflow: hidden;
}

.Preferences-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.08);
  justify-content: space-between;
  cursor: pointer;
}

.Preferences-item:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.2s all ease-in-out;
}

.Preferences-item p, .Preferences-item h4 {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}

.Preferences-item p {
  text-align: right;  
}

.Device-card-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.Device-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* BANNER */

.Family-plan-banner {
  display: flex;
  flex-direction: row;
  background-color: #F7E2C7;
  border-radius: 16px;
  overflow: hidden;
  gap: 24px;
  padding: 24px;
  border: 1px solid rgba(0,0,0,0.08);
}

.Family-plan-banner img {
  width: 240px;
  height: 100%;
}

.Family-plan-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* PREMIUM BANNER */
.Upgrade-banner {
  display: flex;
  flex-direction: row;
  background-color: #1F452F;
  border-radius: 24px;
  overflow: hidden;
  gap: 32px;
  padding: 24px;
  border: 1px solid rgba(0,0,0,0.08);
}

.Upgrade-banner img {
  width: 480px;
  height: 100%;
  border-radius: 16px;
}

.Upgrade-banner-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Upgrade-banner-content h2, .Upgrade-banner-content p {
  color: #F3FFFC;
}

/* RECIPE DETAIL */
.Recipe-cover-empty {
  height: 0px;
}

.Recipe-cover {
  background-size: cover;
  width: 100%;
  height: 320px;
  margin-bottom: 24px;
  border-radius: 0;
}



.Recipe-section-tabs-container {
  display: flex;
  flex-direction: 'row';
  flex-wrap: 'wrap';
  width: 100%;
  justify-content: space-evenly;
  margin-top: 8px;
  gap: 24px;
}

.Recipe-section-tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0;
  width: 100%;
}


.Recipe-section-tabs h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.Recipe-metadata-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
}

.Recipe-metadata {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Ingredients-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
}

.Ingredients-list-item p {
  margin-bottom: 0;
}


.Ingredients-list-item .Button-default {
  padding: 6px;
  border: 1px solid rgba(0,0,0,0.08);
  background-color: white;
}

.Editor-content ul li {
  list-style-type: disc;
  margin-left: 16px;
}

.Twemoji-small {
  height: 24px;
  width: 24px;
  align-self: center;
}


/* PREFERENCES */
.Bubble-list {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 8px;
  /* width: 100%; */
  width: 640px;
  overflow: scroll;
  padding: 8px;
}

.Bubble-list label {
  margin: 0;
}

.Bubble-list::-webkit-scrollbar {
  display: none;
}

.Bubble-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
  border-radius: 48px;
  cursor:pointer;
  height: 80px;
  width: 80px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
  background-color: white;
  border: 1px solid rgba(0,0,0,0);
  font-weight: 500;
}

.Bubble-option .Checkbox, .Bubble-option-active .Checkbox { 
  display: none;
}


.Bubble-option:hover {
  background-color: rgba(0,0,0,0.04);
  transition: 0.2s all ease-in-out;
}

.Bubble-option-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
  border-radius: 48px;
  cursor:pointer;
  height: 80px;
  width: 80px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
  background-color: rgba(0, 155, 60, 0.12);
  border: 1px solid rgba(9, 131, 72, 1.0);
}

.Bubble-option-active h5 {
  color: rgba(9, 131, 72, 1.0);  
}

/* .Bubble-option-active:hover {
  background-color: rgba(0, 155, 60, 0.08);
} */

.Bubble-option p, .Bubble-option-active p {
  margin-bottom: 0px;
}

.Bubble-option-active p {
  color: rgba(9, 131, 72, 1.0);  
  font-weight: 500;
}


.Bubble-option h5 {
  margin-top: 8px;
  text-align: center;
}


/* EMPTY STATES */
.Empty-state-container {
  /* height: 50vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.Empty-state-content {
  width: 440px;
  max-width: 640px;
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid black; */
  padding: 24px;
}

.Empty-state-illustration {
  border-radius: 8px;
  width: 320px;
}

.Empty-state-container h3 {
  margin-bottom: 12px;
  
}


/* AUTHENTICATION */
.Auth-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Auth-container form {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px; 
  justify-content: center;
  align-items: center;
}

.Auth-container small {
  text-align: center;
}

.Auth-container input {
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0);
  padding: 16px;
  width: 320px;
}

.Auth-container button {
  padding: 16px;
  width: 360px;
}

.Auth-buttons-nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.Image-bottom-left-corner, .Image-bottom-right-corner {
  position: absolute;
  width: 480px;
  bottom: -80px;
}

.Image-bottom-left-corner {
  left: 0;
}

.Image-bottom-right-corner {
  right: 0;
}



/* FEEDBACK */
.Feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  border-radius: 16px;
}

.Feedback-container-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Feedback-container textarea, .Feedback-container input {
  width: 280px;
}


.Feedback-container label {
  width: 360px;
  text-align: center;
}

.Feedback-rating-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  border-radius: 100%;
  cursor: pointer;
  color: rgba(0,0,0,0.4);
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 4%);
}

.Feedback-rating-bubble-active {
  background-color: rgba(0, 155, 60, 0.12);
  color: #27272A;
  border: 1px solid #27272A;
}

/* MEDIA QUERIES */
@media screen and (max-width: 960px) {

  body, .ql-editor, .Editor-content, h1, h2, h3, h4 {
    font-weight: 500;
  }


  h2 {
    font-size: 1.48em;
    font-weight: 600;
    letter-spacing: -0.064em;
  }

  h3 {
    font-size: 0.96em;
    font-weight: 600;
    letter-spacing: -0.064em;
  }

  h4 {
    font-size: 0.72em;
    font-weight: 600;
    letter-spacing: -0.064em;
    margin-top: 0;
  }

  small {
    font-size: 0.76em;
    font-weight: 400;
    letter-spacing: -0.016em;
    opacity: 0.8;
  }

  .Greetings {
    font-size: 1.32em;
    font-weight: 600;
    letter-spacing: -0.064em;
  }

  .Bottom-nav-container {
    padding: 0;
  }

  .Bottom-nav-tab p, .Bottom-nav-tab .active p {
    letter-spacing: -0.048em;
  }

  .Main-nav-container {
    margin-bottom: 0;
    /* box-shadow: 8px 8px white; */
    box-shadow: 0px 4px 8px white;
  }

  .Main-nav-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Logo-small {
    width: 32px;
  }

  .Main-nav-tab p {
    /* display: none; */
    font-size: 11px;
  }

  .Main-nav-tab a {
    display: flex;
    flex-direction: row;
    padding: 4px 8px;
    /* padding: 0px; */
    margin-left: 0;
    margin-right: 0;
    gap: 0px;
  }

  .Page-container {
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 8px;
  }
  
  .Page-small, .Page-xsmall {
    width: 100%;
  }

  .Page-medium {
    width: 100%;
  }

  .Page-large {
    width: 100%;
  }

  .Page-header {
    flex-direction: column;
    /* align-items: center; */
    /* text-align: center; */
    margin-top: 72px;
    box-sizing: content-box;
  }

  .Page-title {
    align-self: flex-start;
    width: 100%;
  }

  /* .Modal-content-container .Page-title {
    display: block;
  } */

  input[type=date] {
    width: 132px;
    height: 40px;
  }

  .Page-header .Search-bar {
    width: 94%;
    margin: 8px;
  }

  .Page-cover-container-hero {
    object-fit: cover;
    /* height: 240px; */
    margin-top: 48px;
  }

  .Logo-full {
    width: 120px;
  }

  .Section-hero {
    flex-direction: column-reverse;
    width: 100%;
    gap: 40px;
    margin-top: 56px;
  }

  .Section-hero-content {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    align-items: center;
    text-align: center;
  }

  .Section-hero-image {
    width: 800px;
  }

  .Section {
    flex-direction: column;
    padding: 0;
    background-color: #FFFFFF;
  }

  .Section:nth-child(3){
    flex-direction: column-reverse;
  }

  .Section:nth-child(5){
    flex-direction: column-reverse;
  }

  .Section-content-text {
    padding: 0;
    padding-bottom: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .Avatar-xLarge {
    width: 80px;
    height: 80px;
  }

  .Bubble-list {
    overflow: scroll;
    width: 100%;
  }

  .Button-group {
    /* flex-direction: column; */
    box-sizing: border-box;
  }

  .Button-icon {
    padding: 8px;
  } 

  .Button-primary, .Button-secondary, .Button-default, .Button-subtle, .Button-delete, .Button-full, .Button-generate, .Button-connect, .Button-subtle {
    padding: 8px 12px;
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: -0.032em;
  }

  .Button-icon {
    padding: 8px;
  }

  .Carousel-slideshow {
    width: 100%;
  }

  .Carousel-slide {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .Carousel-slideshow-dots {
    width: 100%;
    left: 45%;
  }

  .Carousel-slideshow-dot {
    padding: 6px;
    font-size: 12px;
  }

  .fc-event-main .Recipe-card {
    width: 100% !important;
    overflow: hidden;
  }

  .Recipe-card-content {
    padding: 8px;
  }

  .fc-event-main .Recipe-thumbnail {
    height: 80px !important;
  }

  .fc-list-event {
    margin-bottom: 16px !important;
  }

  .Chat-bubble-container {
    width: 100%;
    margin: 8px;
  }

  .Chat-bubble-from, .Chat-bubble-to {
    font-size: 16px;
  }
  

  .Modal-content-container {
    /* width: 380px; */
    width: calc(100% - 48px);
  }

  .Section-grocery {
    flex-direction: column;
  }

  .Upgrade-banner {
    gap: 16px;
  }

  .Family-plan-banner, .Upgrade-banner {
    flex-direction: column;
    align-items: center;
  }

  .Family-plan-banner-content, .Upgrade-banner-content {
    align-items: center;
    text-align: center;
  }

  .Upgrade-banner img {
    width: 100%;
  }

  .Search-bar {
    margin-bottom: 0;
  }

  .fc {
    height: 100vh;
  }

  .fc-scrollgrid-sync-inner {
    padding: 0px !important;
  }

  .fc-toolbar-title {
    font-size: 1.0em !important;
  }

  .fc-scrollgrid-liquid {
    height: 100vh;
  }

  .fc-col-header-cell-cushion {
    font-size: 10px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    display: flex !important;
    /* flex-direction: column !important; */
    gap: 4px !important;
    padding-left: 16px;
    padding-right: 16px;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    width: 100%;
    margin-bottom: 12px;
    font-size: 1.4em;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    width: 48%;
    justify-content: flex-start;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    width: 48%;
    justify-content: flex-end;
  }

  .fc .fc-button {
    padding: 6px 8px !important;
  }

  .Floating-action-bar {
    padding: 4px;
    gap: 4px;
  }
  

  .Calendar-event {
    justify-content: flex-start;
    overflow: hidden;
  }
  .Calendar-event-thumbnail {
    height: 56px;
  }

  .Calendar-event-info {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 8px;
    white-space: normal;
    box-sizing: content-box;
  }

  .Calendar-event-info h4 {
    font-size: 12px;
    font-weight: 600;
    margin-top: 2px;
  }

  .Calendar-event-info small {
    font-size: 9px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  /* .Calendar-add-container {
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
  } */

  .Calendar-add-container .Button-icon {
    padding: 4px;
    border: none;
    display: none;
  }

  .fc .fc-daygrid-day-top {
    font-weight: 600;
    font-size: 12px;
  }

  .fc-day-today .fc-col-header-cell-cushio, .fc .fc-col-header-cell-cushion {
    font-weight: 500;
    font-size: 0.48em;
  }

  .Recipe-card {
    box-sizing: border-box;
  }

  .Recipe-mini-card small {
    font-size: 0.64em;
    letter-spacing: -0.08em;
    line-height: 1.2em;

  }

  /* .Recipe-card-content {
    padding: 8px;
  } */

  /* .Recipe-card-content .Button-group{
    flex-direction: column;
  } */

  .Recipe-card:hover {
    transform: rotate(0deg) translateY(0px);
  }

  .Home-page .Recipe-card {
    width: 100% !important;
  }

  .Home-page .Recipe-card .Recipe-thumbnail {
    height: 320px;
  }

  .Home-page .Recipe-card .Recipe-card-content {
    padding: 8px;
    padding-bottom: 4px;
  }

  .Recipe-grid .Recipe-card {
    width: calc((100% - 8px)/2) !important;
  }

  .Recipe-card .Recipe-card-header {
    align-items: center;
  }

  .Recipe-grid .Recipe-card .Recipe-card-header .ant-btn {
    padding: 0px;
    width: 24px;
    height: 24px;
  }

  .Recipe-grid .Recipe-card-content {
    padding: 8px;
    gap: 4px;
  }

  .Recipe-grid .Recipe-card-content .ant-btn {
    padding: 4px 8px;
    font-size: 11px;
  }

  .Recipe-grid .Recipe-card-content .ant-btn svg {
    width: 16px;
    height: 16px;
  }

  .Recipe-grid .Recipe-card-content h4 {
    font-size: 0.72em;
    font-weight: 600;
    letter-spacing: -0.048em;
    white-space: pre-line;
    /* margin-bottom: 8px; */
  }

  .Recipe-grid .Recipe-thumbnail {
    height: 80px;
  }

  .Recipe-section-tabs-container {
    flex-direction: column;
  }

  .Recipe-section-tabs-container label {
    margin-top: 0;
  }

  .Recipe-metadata-container {
    flex-direction: column;
    width: 100%;
  }

  .Recipe-metadata {
    font-size: 16px;
    gap: 16px;
  }

  .Recipe-card-bottom-actions {
    gap: 0;
  }

  .Recipe-grid .Heart-filled {
    transform: scale(1.0);
  }

  .Recipe-create-modal {
    padding: 8px;
  }

  .Collection-card-list {
    flex-direction: column;
  }

  .Collection-card-list .Recipe-card {
    width: 100% !important;
  }

  .Collection-card-list .Recipe-card img {
    width: 100%;
  }


  .Plan-page-container .Page-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .Plan-sidebar {
    height: 50vh;
    width: 88vw;
    padding: 24px;
    z-index: 95;
    position: fixed;
    top: auto;
    bottom: 56px;
    right: auto;
    left: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* background-color: red; */
    border-top: 1px solid rgba(0,0,0,0.08)
  }

  .Plan-sidebar h3 {
    font-size: 16px;
  }

  .Plan-sidebar h4 {
    font-size: 11px;
    padding-left: 4px;
    font-weight: 600;
  }
  
  .Plan-sidebar ul {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: scroll;
  }

  .Plan-sidebar .Recipe-card {
    padding: 0;
    gap: 4px;
    min-width: 80px;
    max-width: 80px;
    height: 96px;
    overflow: hidden;
    border-radius: 8px;
  }

  .Plan-sidebar .Recipe-card-content {
      padding: 0 0 0 4px;
  }



  .Plan-sidebar .Recipe-thumbnail {
    height: 48px;
    border-radius: 0;
  }

  .Plan-sidebar small {
    display: none;
  }

  .Plan-sidebar button {
    padding: 6px 12px;
    display: none;
  }


  .Avatar-small {
    width: 16px;
    height: 16px;
  }

  .Card {
    width: auto;
  }

  .Card-group {
    flex-direction: column;
  }

  .Grocery-container {
    flex-direction: column;
  }

  .Grocery-cart-container {
    width: auto;
  }

  .Grocery-area {
    width: auto;
    margin-right: 0;
  }
  

  .Grocery-category-tile {
    /* width: 28%; */
  }

  .Pills {
    width: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .Device-icon-container {
    width: 32px;
    height: 32px;
  }

  .Preferences-card-list {
    flex-direction: column;
  }

  .Preferences-header {
    padding: 0;
    padding-top: 16px;
  }

  .List-item-bordered {
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* align-items: flex-start; */
    justify-content: center;
    padding: 8px;
  }

  .List-item-bordered h4 {
    /* margin-top: 4px; */
  }

  .List-item-bordered-value {
    width: 100%;
    display: flex;
    flex-direction: row;
    order: 3;
    margin-left: 48px;
    margin-top: -16px;
    justify-content: flex-start;
  }

  .Empty-state-content {
    width: 100%;
    margin-bottom: 24px;
  }

  .Image-bottom-left-corner, .Image-bottom-right-corner {
    /* position: absolute;
    width: 200px;
    bottom: -48px; */
    display: none;
  }
  
  .Image-bottom-left-corner {
    left: 0;
  }
  
  .Image-bottom-right-corner {
    right: 0;
  }

}







